import React from 'react';

import Logo from '../common/Logo';
import { Card, Header, Wrapper, SubheaderWhite } from './styles';
import Layout from '../Layout';

const SubscribePage = () => {
  return (
    <Layout showLogo={false}>
      <Wrapper>
        <Card>
          <Logo width={160} />
          <Header>Link Expired</Header>
          <SubheaderWhite>
            Your email verification link has expired
          </SubheaderWhite>
        </Card>
      </Wrapper>
    </Layout>
  );
};

export default SubscribePage;
