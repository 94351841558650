import React, { createContext, ReactNode, useContext } from 'react';
import {
  CreateLocalTrackOptions,
  ConnectOptions,
  LocalAudioTrack,
  LocalVideoTrack,
  Room,
  TwilioError,
  LocalTrack,
} from 'twilio-video';
// import { SelectedParticipantProvider } from './useSelectedParticipant/useSelectedParticipant';
//
// import AttachVisibilityHandler from './AttachVisibilityHandler/AttachVisibilityHandler';
// import useHandleRoomDisconnectionErrors from './useHandleRoomDisconnectionErrors/useHandleRoomDisconnectionErrors';
// import useHandleOnDisconnect from './useHandleOnDisconnect/useHandleOnDisconnect';
// import useHandleTrackPublicationFailed from './useHandleTrackPublicationFailed/useHandleTrackPublicationFailed';
// import useScreenShareToggle from './useScreenShareToggle/useScreenShareToggle';

import useLocalTracks from '../../hooks/useLocalTracks';
import useRoom from '../../hooks/useRoom';
import AttachVisibilityHandler from './AttachVisibilityHandler';
import { SelectedParticipantProvider } from '../../hooks/useSelectedParticipant';
import { Callback, ErrorCallback } from '../../types/twilio';

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export interface IVideoContext {
  room: Room;
  localTracks: (LocalAudioTrack | LocalVideoTrack)[];
  isConnecting: boolean;
  connect: (token: string, tracks: LocalTrack[]) => Promise<void>;
  onError: ErrorCallback;
  onDisconnect: Callback;
  disconnect: () => void;
  getLocalVideoTrack: (
    newOptions?: CreateLocalTrackOptions,
  ) => Promise<LocalVideoTrack>;
  getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>;
  isAcquiringLocalTracks: boolean;
  removeLocalVideoTrack: () => void;
  getAudioAndVideoTracks: () => Promise<LocalTrack[] | void>;
}

export const VideoContext = createContext<IVideoContext>(null!);

interface VideoProviderProps {
  options?: ConnectOptions;
  onError?: ErrorCallback;
  onDisconnect?: Callback;
  children: ReactNode;
}

export function VideoProvider({
  options,
  children,
  onError = () => {},
  onDisconnect = () => {},
}: VideoProviderProps) {
  const onErrorCallback = (error: TwilioError) => {
    console.log(`ERROR: ${error.message}`, error);
    onError(error);
  };

  const {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  } = useLocalTracks();

  const { room, isConnecting, connect, disconnect } = useRoom(onErrorCallback, {
    ...options,
  });

  // Register onError and onDisconnect callback functions.

  // TODO:
  // useHandleRoomDisconnectionErrors(room, onError);
  // useHandleTrackPublicationFailed(room, onError);
  // useHandleOnDisconnect(room, onDisconnect);

  return (
    <VideoContext.Provider
      value={{
        room,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        onDisconnect,
        disconnect,
        getLocalVideoTrack,
        getLocalAudioTrack,
        connect,
        isAcquiringLocalTracks,
        removeLocalVideoTrack,
        getAudioAndVideoTracks,
        // TODO: create another provider for app state
        // App state
      }}>
      <SelectedParticipantProvider room={room}>
        {children}
      </SelectedParticipantProvider>
      {/* 
        The AttachVisibilityHandler component is using the useLocalVideoToggle hook
        which must be used within the VideoContext Provider.
      */}
      <AttachVisibilityHandler />
    </VideoContext.Provider>
  );
}

export function useVideoContext() {
  const context = useContext(VideoContext);
  if (!context) {
    throw new Error('useVideoContext must be used within a VideoProvider');
  }
  return context;
}
