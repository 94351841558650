import { useEffect, useState } from 'react';
import { useVideoContext } from '../components/Video/VideoProvider';

type RoomStateType =
  | 'disconnected'
  | 'connected'
  | 'reconnecting'
  | 'reconnected'
  | null;

export default function useRoomState() {
  const { room } = useVideoContext();
  const [state, setState] = useState<RoomStateType>(null);

  useEffect(() => {
    const setRoomState = () => {
      setState((room.state || 'disconnected') as RoomStateType);
    };

    setRoomState();
    room
      .on('disconnected', setRoomState)
      .on('reconnected', setRoomState)
      .on('reconnecting', setRoomState)
      .on('connected', setRoomState);

    return () => {
      room
        .off('disconnected', setRoomState)
        .off('reconnected', setRoomState)
        .off('reconnecting', setRoomState)
        .on('connected', setRoomState);
    };
  }, [room]);

  return state;
}
