import React, { FC, useEffect, useState } from 'react';
import { MODAL_TRANSITION } from '../../../constants';
import { Wrapper } from './styles';

interface Props {
  open: boolean;
}

let timer: number;

const Modal: FC<Props> = ({ open = false, children }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (open) {
      setVisible(true);
    }
    if (!open) {
      timer = setTimeout(() => setVisible(false), MODAL_TRANSITION + 10);
    }

    return () => clearTimeout(timer);
  }, [open]);

  return visible ? <Wrapper isShown={open}>{children}</Wrapper> : null;
};

export default Modal;
