import { useEffect, useState } from 'react';
import { API_URL } from '../constants';

type FetchTokenResponse = {
  callingUserId: 'string';
  receivingUserId: 'string';
  roomName: 'string';
  token: 'string';
};

type FetchTokenParams = {
  roomPassword: string;
  roomName: string;
};

export const useFetchToken = () => {
  const [isFetching, setIsFetching] = useState(false);

  const fetchToken = async (url: string, params: FetchTokenParams) => {
    try {
      setIsFetching(true);
      const tokenResponse = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
        },
        body: JSON.stringify({
          roomName: params.roomName,
          roomPassword: params.roomPassword,
        }),
      });

      if (!tokenResponse.ok) return null;

      const data = await tokenResponse.json();

      return data as FetchTokenResponse;
    } catch (e) {
      setIsFetching(false);
      console.error(e?.toString() || 'ERROR');
    }
  };

  return [fetchToken, isFetching] as [typeof fetchToken, boolean];
};

export const useCreateTestRoom = () => {
  // ! Link
  // ? the last generated room
  // roomName: "6bd-h43-d68"
  // roomPassword: "c3e7f8jjai"
  // * Link: http://localhost:3000/?n=6bd-h43-d68&p=c3e7f8jjai

  const createRoom = async () => {
    try {
      const response = await fetch(`${API_URL}/user/create-room`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': 'selva-key',
        },
      });

      const data = await response.json();
      console.dir(data);
    } catch (e) {
      console.error(e, 'Room creation failed');
    }
  };

  useEffect(() => {
    // @ts-ignore
    window.createRoom = createRoom;
  }, []);
};
