import React, { createContext, FC, useContext, useState } from 'react';

export interface AppStateContext {
  roomName?: string;
  roomPassword?: string;
  identity?: string;
  setUrlTokenParams: (params: UrlTokenParams | null) => void;
  isMuted: boolean;
  toggleMuted: () => void;
}

export const AppStateContext = createContext<AppStateContext>(null!);

type UrlTokenParams = {
  roomName: string;
  roomPassword: string;
};

const AppStateProvider: FC = ({ children }) => {
  const [urlParams, setUrlParams] = useState<UrlTokenParams | null>(null);
  const [isMuted, setIsMuted] = useState(false);

  const toggleMuted = () => setIsMuted((prev) => !prev);

  return (
    <AppStateContext.Provider
      value={{
        isMuted,
        toggleMuted,
        roomName: urlParams?.roomName,
        roomPassword: urlParams?.roomPassword,
        setUrlTokenParams: (params: UrlTokenParams | null) => {
          setUrlParams(() => params);
        },
      }}>
      {children}
    </AppStateContext.Provider>
  );
};

export function useAppStateContext(): AppStateContext {
  const context = useContext(AppStateContext);

  if (!context) {
    throw new Error(
      'useAppStateContext must be used within a AppStateProvider',
    );
  }
  return context;
}

export default AppStateProvider;
