import React, { useRef, useEffect } from 'react';
import { IVideoTrack } from '../../types/twilio';

import styled from 'styled-components';
import { Track } from 'twilio-video';
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack';
import useVideoTrackDimensions from '../../hooks/useVideoTrackDimensions';
import { useAppStateContext } from '../../containers/AppStateProvider';

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

interface VideoTrackProps {
  track: IVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority | null;
}

export default function VideoTrack({
  track,
  isLocal,
  priority,
}: VideoTrackProps) {
  const ref = useRef<HTMLVideoElement>(null!);
  const mediaStreamTrack = useMediaStreamTrack(track);
  const dimensions = useVideoTrackDimensions(track);
  const { isMuted } = useAppStateContext();

  const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);

  useEffect(() => {
    const el = ref.current;
    if (!el) return;

    track.attach(el);
    return () => {
      track.detach(el);
    };
  }, [track, priority]);

  useEffect(() => {
    if (!ref.current) return;
    ref.current.muted = isMuted;
  }, [isMuted]);

  // The local video track is mirrored if it is not facing the environment.
  const isFrontFacing =
    mediaStreamTrack?.getSettings().facingMode !== 'environment';

  const style = {
    transform: isLocal && isFrontFacing ? 'rotateY(180deg)' : '',
    objectFit: isPortrait ? ('contain' as const) : ('cover' as const),
  };

  return <Video ref={ref} style={style} />;
}
