import { ENDPOINT_RATE } from '../constants';
import { useState } from 'react';
import { Rating } from '../types';

type RateParams = {
  roomName: string;
  rating: Rating;
};

type RateResponse = {
  status: 'ok';
};

export const useRate = () => {
  const [isFetching, setIsFetching] = useState(false);

  const rate = async ({ roomName, rating }: RateParams) => {
    if (!roomName || !rating)
      return Promise.reject('Subscribe: expected 2 arguments');

    try {
      setIsFetching(true);
      const response = await fetch(ENDPOINT_RATE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          accept: 'application/json',
        },
        body: JSON.stringify({
          roomName,
          rating,
        }),
      });

      if (!response.ok) {
        return Promise.reject('Something went wrong, please try again later');
      }

      const data: RateResponse = await response.json();

      if (data.status !== 'ok') {
        return Promise.reject('Something went wrong, please try again later');
      }

      console.log('Rating succeeded');
    } catch (e) {
      console.error(e, 'Rating failed');
    } finally {
      setIsFetching(false);
    }
  };

  return [rate, isFetching] as [typeof rate, boolean];
};
