import styled, { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';
import { FONTS } from '../../constants/theme';
import { backgroundGradient, media, whiteColor } from '../../helpers/theme';
import background from '../../assets/icons/background.svg';

// noinspection CssUnknownTarget
const fonts = css`
  @font-face {
    font-family: ${FONTS.majestiBanner};
    font-style: normal;
    font-weight: 700;
    src: local(${FONTS.majestiBanner}),
      url(${process.env.PUBLIC_URL}/fonts/MajestiBanner-Bold.otf)
        format('opentype');
  }
  @font-face {
    font-family: ${FONTS.proximaNova};
    font-style: normal;
    font-weight: 300;
    src: local(${FONTS.proximaNova}),
      url(${process.env.PUBLIC_URL}/fonts/ProximaNova-Light.otf)
        format('opentype');
  }
  @font-face {
    font-family: ${FONTS.proximaNova};
    font-style: normal;
    font-weight: 600;
    src: local(${FONTS.proximaNova}),
      url(${process.env.PUBLIC_URL}/fonts/ProximaNova-Semibold.otf)
        format('opentype');
  }
  @font-face {
    font-family: ${FONTS.proximaNova};
    font-style: normal;
    font-weight: 700;
    src: local(${FONTS.proximaNova}),
      url(${process.env.PUBLIC_URL}/fonts/ProximaNova-Bold.otf)
        format('opentype');
  }
`;

export const GlobalStyle = createGlobalStyle`
  ${normalize};
  ${fonts};
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  body {
    max-height: 100%;
    min-height: 90vh;
    width: 100%;
    
    ${media.up('tablet')} {
        min-height: 100vh;
    }
  }
  main {
    display: flex;
    min-height: 90vh;
    width: 100%;
    
     ${media.up('tablet')} {
        min-height: 100vh;
    }
  }
  
  ${media.down('tablet')} {
    main {
      align-items: center;    
    }
    
  }
  
  ${media.up('tablet')} {
    body {
      background: url(${background}), ${backgroundGradient};
    }
    
    main {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        grid-template-rows: 150px calc(100vh - 400px);
          
        & > div {
          grid-column: 2;    
        }
    }
  }
  
  ${media.up('desktop')} {
    main {
      grid-template-rows: 1fr;
    }
  }
  
`;

export const Wrapper = styled.div`
  color: ${whiteColor};
  display: flex;
  ${media.down('tablet')} {
    width: 100%;
    height: 100%;
  }
  ${media.up('tablet')} {
    margin: auto;
  }
`;

export const LogoWrapper = styled.div`
  display: none;
  ${media.up('tablet')} {
    display: flex;
    height: fit-content;
  }

  ${media.between('phone', 'desktop')} {
    margin: 10px auto 0;
  }

  ${media.up('desktop')} {
    display: flex;
    position: absolute;
    top: 20px;
    left: 0;
  }
`;

export const Gradient = styled.div`
  background: ${backgroundGradient};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
`;

export const BackgroundImage = styled.div`
  background: ${backgroundGradient};
  z-index: -1;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url(${background}), ${backgroundGradient};
  ${media.down('tablet')} {
    display: none;
  }
`;
