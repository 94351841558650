import React, { useCallback, useRef } from 'react';
import IconButton from '../common/IconButton';
import { HorizontalWrapper, SmallButton, VerticalWrapper } from './styles';
import useLocalAudioToggle from '../../hooks/useLocalAudioToggle';
import useLocalVideoToggle from '../../hooks/useLocalVideoToggle';

type Props = {
  onCancel: () => void;
};

const Actions = ({ onCancel }: Props) => {
  const [audioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const [videoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 200) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  const onRecord = () => {};

  return (
    <>
      <HorizontalWrapper>
        <IconButton onClick={onCancel}>close</IconButton>
      </HorizontalWrapper>
      <VerticalWrapper>
        <SmallButton onClick={toggleVideo}>
          {videoEnabled ? 'camera' : 'cameraMutedWhite'}
        </SmallButton>
        <SmallButton onClick={toggleAudioEnabled}>
          {audioEnabled ? 'micWhite' : 'micMutedWhite'}
        </SmallButton>
        <SmallButton onClick={onRecord}>record</SmallButton>
      </VerticalWrapper>
    </>
  );
};

export default Actions;
