import Input, { Props } from './';
import React, { FC, useState } from 'react';
import { EndAdornmentButton } from './styles';
import { ICONS } from '../../../constants';

const ShowPasswordIcon = ICONS.eyeWhite;
const HidePasswordIcon = ICONS.eyeHiddenWhite;

const InputPassword: FC<Props> = (props) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => setShowPassword((prev) => !prev);

  return (
    <Input
      {...props}
      label={'Password'}
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <EndAdornmentButton onClick={togglePassword}>
          {showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
        </EndAdornmentButton>
      }
    />
  );
};

export default InputPassword;
