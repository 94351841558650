import React, { FC, MouseEventHandler } from 'react';
import { LinkButton, StyledButton } from './styles';
import { Button as ButtonType } from '../../../types';

interface Props {
  onClick?: MouseEventHandler;
  color?: ButtonType;
  inverted?: boolean;
  type?: 'submit' | 'reset' | 'button';
  form?: string;
  href?: string;
  disabled?: boolean;
}

const Button: FC<Props> = ({
  children,
  onClick,
  color = 'primary',
  href,
  ...props
}) => {
  return href ? (
    <LinkButton {...props} href={href} color={color}>
      {children}
    </LinkButton>
  ) : (
    <StyledButton color={color} onClick={onClick} {...props}>
      {children}
    </StyledButton>
  );
};

export default Button;
