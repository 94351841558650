import React from 'react';
import { useHistory } from 'react-router-dom';
import { PRIVACY_POLICY_URI, TERMS_URI } from '../../constants';
import { GRAND_ACCESS } from '../../constants/routes';
import Card from '../Cards/Card';

import Link from '../common/Link';
import Actions from './Actions';
import { Terms, Button } from './styles';

import { useUrlTokenParams } from '../../hooks/useUrlTokenParams';
import { useAppStateContext } from '../../containers/AppStateProvider';
import Layout from '../Layout';

const ReceiveCallPage = () => {
  const history = useHistory();
  const tokenParams = useUrlTokenParams();
  const { setUrlTokenParams } = useAppStateContext();

  const handleCancel = () => {
    history.push('/');
    setUrlTokenParams(null);
  };

  const handleApply = async () => {
    if (tokenParams === null) return;
    setUrlTokenParams(tokenParams);
    history.push(GRAND_ACCESS);
  };

  if (tokenParams === null) {
    return (
      <Layout>
        <Card
          hasDownloadAppLink
          titleType={'primary'}
          title={'It seems like this link has expired or used.'}
          action={<Button color={'default'}>Head over to our FAQ</Button>}
          desc={
            <Terms>
              Please contact us if you are having issues with accepting calls
            </Terms>
          }
        />
      </Layout>
    );
  }

  return (
    <Layout>
      <Card
        title={'Santa’s Calling'}
        hasDownloadAppLink
        hasAvatar
        subtitle={'Click on the video icon \n below to accept this call.'}
        desc={
          <Terms>
            {'By accepting this call, you are agreeing to the \n Enchant '}
            <Link href={TERMS_URI}>Terms of Service</Link>
            {', and '}
            <Link href={PRIVACY_POLICY_URI}>Privacy Policy</Link>
          </Terms>
        }
        action={<Actions onCancel={handleCancel} onApply={handleApply} />}
      />
    </Layout>
  );
};

export default ReceiveCallPage;
