import React, { MouseEventHandler } from 'react';
import { ICONS } from '../../../constants';
import { Icon } from '../../../types';
import { Caption, StyledButton, Wrapper } from './styles';

interface Props {
  caption?: string;
  children: Icon;
  onClick: MouseEventHandler;
}

const IconButton = ({ caption, children, onClick, ...props }: Props) => {
  const IconComponent = ICONS[children];

  if (!IconComponent) return null;

  return (
    <Wrapper>
      <StyledButton icon={children} onClick={onClick} {...props}>
        <IconComponent />
      </StyledButton>
      {caption ? <Caption>{caption}</Caption> : null}
    </Wrapper>
  );
};

export default IconButton;
