import styled from 'styled-components';
import { ROUND_IMAGE_SIZE } from '../../constants';

import {
  backgroundColor,
  body14,
  body20,
  getShadow,
  goldGradient,
  header24,
  majestiHeader,
  media,
  secondaryMainColor,
  shadowColor,
} from '../../helpers/theme';
import BaseLink from '../common/Link';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  min-height: 500px;

  ${media.up('tablet')} {
    background: ${backgroundColor};
    border-radius: ${({ theme }) => theme.borderRadius};
    box-shadow: ${getShadow(0)} ${shadowColor};
    padding: 32px;
    width: 400px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin: auto;
`;

export const ImageWrap = styled.div`
  border-radius: 50%;
  border: 2px solid ${secondaryMainColor};
  filter: drop-shadow(${getShadow(0)} ${shadowColor});
  max-height: ${ROUND_IMAGE_SIZE}px;
  max-width: ${ROUND_IMAGE_SIZE}px;
  overflow: hidden;
  ${media.safari()} {
    filter: none;
    box-shadow: ${getShadow(0)} ${shadowColor};
  }
`;

export const Image = styled.img`
  height: 100%;
  object-fit: cover;
  width: 100%;

  ${media.safari()} {
    object-position: 0 -50px;
  }
`;

export const Title = styled.h1`
  ${majestiHeader};
  color: ${secondaryMainColor};
  text-align: center;
  margin: 24px 0 21px;
`;

export const Subtitle = styled.p`
  ${body20};
  text-align: center;
  white-space: pre-wrap;
  margin: 0 0 32px;
`;

export const Link = styled(BaseLink)`
  ${body14};
  text-align: center;
  margin-top: 32px;
`;

export const Header = styled.h1`
  ${header24};
  background: linear-gradient(90.01deg, ${goldGradient});
  color: ${secondaryMainColor};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`;
