/* eslint-disable import/no-extraneous-dependencies */
import _flowRight from 'lodash/fp/flowRight';
import _curry from 'lodash/fp/curry';
import _isArray from 'lodash/fp/isArray';
import _replace from 'lodash/fp/replace';

export const compose = _flowRight;

export const curry = _curry;

export const isArray = _isArray;

export const replace = _replace;
