import styled from 'styled-components';
import {
  body16,
  button,
  goldGradient,
  header24,
  majestiHeader,
  media,
  primaryLightColor,
  secondaryMainColor,
} from '../../helpers/theme';
import BasePaper from '../common/Paper';
import BaseButton from '../common/Button';

export const Wrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  & > *:first-child {
    margin-bottom: 18px;
  }
`;

export const Card = styled(BasePaper)`
  align-items: center;
`;

export const HeaderPrimary = styled.h1`
  ${header24};
  background: linear-gradient(90.01deg, ${goldGradient});
  color: ${secondaryMainColor};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`;

export const Header = styled.h1`
  ${majestiHeader};
  color: ${secondaryMainColor};
  text-align: center;
`;

export const Subheader = styled.p`
  ${body16};
  color: ${secondaryMainColor};
  white-space: pre-wrap;
  text-align: center;
`;

export const SubheaderWhite = styled.p`
  ${body16};
  color: ${primaryLightColor};
  white-space: pre-wrap;
  text-align: center;
  margin-top: 0;
`;

export const Button = styled(BaseButton)`
  width: 100%;
`;

export const AppLinkWrapper = styled(BasePaper)`
  // ${media.down('tablet')} {
  //   position: absolute;
  //   bottom: 24px;
  // }
`;

export const AppLink = styled.a`
  ${button};
  color: ${secondaryMainColor};
  letter-spacing: -0.08px;
  text-align: center;
  text-decoration: unset;
`;
