import { useEffect, useState } from 'react';
import { Participant } from 'twilio-video';

type ParticipantStateType =
  | 'disconnected'
  | 'connected'
  | 'reconnecting'
  | 'reconnected';

export default function useParticipantState(participant?: Participant) {
  const [state, setState] = useState<ParticipantStateType>('disconnected');

  useEffect(() => {
    if (!participant) return;

    const setParticipantState = (p?: Participant) => {
      setState((participant?.state || 'disconnected') as ParticipantStateType);
    };

    setParticipantState();

    participant
      .on('disconnected', setParticipantState)
      .on('reconnected', setParticipantState)
      .on('reconnecting', setParticipantState)
      .on('connected', setParticipantState);

    return () => {
      participant
        .off('disconnected', setParticipantState)
        .off('reconnected', setParticipantState)
        .off('reconnecting', setParticipantState)
        .on('connected', setParticipantState);
    };
  }, [participant]);

  return state;
}
