import { FC } from 'react';
import { ReactComponent as Close } from '../assets/icons/close.svg';
import { ReactComponent as Video } from '../assets/icons/video.svg';
import { ReactComponent as MicBlack } from '../assets/icons/mic_black.svg';
import { ReactComponent as MicWhite } from '../assets/icons/mic_white.svg';
import { ReactComponent as Camera } from '../assets/icons/camera.svg';
import { ReactComponent as InboundCall } from '../assets/icons/inbound_call.svg';
import { ReactComponent as Record } from '../assets/icons/record.svg';
import { ReactComponent as Frown } from '../assets/icons/frown.svg';
import { ReactComponent as Meh } from '../assets/icons/meh.svg';
import { ReactComponent as Smile } from '../assets/icons/smile.svg';

import { ReactComponent as Eye } from '../assets/icons/eye.svg';
import { ReactComponent as EyeHidden } from '../assets/icons/eye_hidden.svg';
import { ReactComponent as EyeWhite } from '../assets/icons/eye_white.svg';
import { ReactComponent as EyeHiddenWhite } from '../assets/icons/eye_hidden_white.svg';

import { ReactComponent as MicMutedWhite } from '../assets/icons/mic_muted_white.svg';
import { ReactComponent as CameraMutedWhite } from '../assets/icons/camera_muted_white.svg';

export const MIN_YEARS = 13;

export const ROUND_IMAGE_SIZE = 108;

export const ICONS: Record<string, FC> = {
  close: Close,
  video: Video,
  micBlack: MicBlack,
  micWhite: MicWhite,
  camera: Camera,
  speaker: InboundCall,
  record: Record,
  micMutedWhite: MicMutedWhite,
  cameraMutedWhite: CameraMutedWhite,
  eye: Eye,
  eyeHidden: EyeHidden,
  eyeWhite: EyeWhite,
  eyeHiddenWhite: EyeHiddenWhite,
};

export enum RATE_VALUES {
  frown = 'frown',
  meh = 'meh',
  smile = 'smile',
}

export const RATE_ICON: Record<keyof typeof RATE_VALUES, FC> = {
  frown: Frown,
  meh: Meh,
  smile: Smile,
};

export enum ButtonTypes {
  'default',
  'primary',
  'secondary',
}

export const APP_URI = 'https://enchantchristmas.com/';
export const TERMS_URI = 'https://enchantchristmas.com/';
export const PRIVACY_POLICY_URI = 'https://enchantchristmas.com/';

export const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ERRORS = {
  email: 'Please specify a valid email',
  birthDate: `You must be older than ${MIN_YEARS} years`,
};

export enum SubscribeInputs {
  email = 'email',
  name = 'name',
  birthDate = 'birthDate',
}

export enum SubscribeActionTypes {
  email = 'setEmail',
  name = 'setName',
  birthDate = 'setBirthDate',
}

export const YEAR_IN_MILLISECONDS = 31536000000;

export const CALENDAR_CLASS = 'enchant-date-picker';
export const CALENDAR_POPPER_CLASS = 'enchant-date-popper';

export const MODAL_TRANSITION = 250;

export const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints['video'] = {
  width: 1280,
  height: 720,
  frameRate: 24,
};

// These are used to store the selected media devices in localStorage
export const SELECTED_AUDIO_INPUT_KEY = 'TwilioVideoApp-selectedAudioInput';
export const SELECTED_AUDIO_OUTPUT_KEY = 'TwilioVideoApp-selectedAudioOutput';
export const SELECTED_VIDEO_INPUT_KEY = 'TwilioVideoApp-selectedVideoInput';

export const TOKEN_PARAM_ROOM_NAME = 'n';
export const TOKEN_PARAM_ROOM_PASSWORD = 'p';

// prettier-ignore
// @ts-ignore
export const API_URL: string = window.API_URL || 'https://dev-api.santacalls.com/api/v1';
// prettier-ignore
// @ts-ignore
export const FB_API_KEY: string = window.FB_API_KEY || 'AIzaSyBmWo2PSRi1CS0IXoH2Q7LEQdQqwPs-_C8';
// prettier-ignore
// @ts-ignore
export const FB_APP_ID: string = window.FB_APP_ID || 'enc-vs';
// prettier-ignore
// @ts-ignore
export const FB_PROJECT_ID: string = window.FB_PROJECT_ID || 'com.enchant.santacalls.dev';

export const ENDPOINT_FETCH_TOKEN = `${API_URL}/webapp/join-room`;
export const ENDPOINT_RATE = `${API_URL}/webapp/rate-call`;
export const ENDPOINT_SUBSCRIBE = `${API_URL}/webapp/subscribe`;

export const isMobile = (() => {
  if (
    typeof navigator === 'undefined' ||
    typeof navigator.userAgent !== 'string'
  ) {
    return false;
  }
  return /Mobile/.test(navigator.userAgent);
})();

export const EMAIL_VERIFICATION_MODE = 'verifyEmail';
export const RESET_PASSWORD_MODE = 'resetPassword';
export const MANAGE_USER_TOKEN_PARAM = 'oobCode';
export const MANAGE_USER_MODE_PARAM = 'mode';
