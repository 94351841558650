import React from 'react';
import { useHistory } from 'react-router-dom';
import Card from '../Cards/Card';
import Actions from './Actions';
import { useVideoContext } from '../Video/VideoProvider';
import { CALL, ROOT } from '../../constants/routes';
import { useFetchToken } from '../../hooks/useFetchToken';
import { ENDPOINT_FETCH_TOKEN } from '../../constants';
import { useAppStateContext } from '../../containers/AppStateProvider';
import Layout from '../Layout';

const GrandAccessPage = () => {
  const history = useHistory();

  const [fetchToken] = useFetchToken();
  const { roomPassword, roomName } = useAppStateContext();
  const { connect, getAudioAndVideoTracks } = useVideoContext();

  const onAccept = async () => {
    try {
      if (!roomPassword || !roomName) {
        return history.push(ROOT);
      }

      const tokenData = await fetchToken(ENDPOINT_FETCH_TOKEN, {
        roomName,
        roomPassword,
      });

      if (!tokenData) {
        return history.push(ROOT);
      }

      const tracks = await getAudioAndVideoTracks();

      await connect(tokenData.token, tracks || []);
      return history.push(CALL);
    } catch (e) {
      history.push(ROOT);
      console.error(e, 'Cannot get devices');
    }
  };

  return (
    <Layout>
      <Card
        hasAvatar
        title={'Santa’s Calling'}
        subtitle={'One more step...'}
        hasDownloadAppLink
        action={<Actions onAccept={onAccept} />}
      />
    </Layout>
  );
};

export default GrandAccessPage;
