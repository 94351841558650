import { useLocation } from 'react-router-dom';
import { TOKEN_PARAM_ROOM_NAME, TOKEN_PARAM_ROOM_PASSWORD } from '../constants';

export const useUrlTokenParams = () => {
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);

  const roomName = searchParams.get(TOKEN_PARAM_ROOM_NAME);
  const roomPassword = searchParams.get(TOKEN_PARAM_ROOM_PASSWORD);

  if (!roomName || !roomPassword) {
    return null;
  }

  return { roomName, roomPassword };
};
