import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import { CALENDAR_CLASS, CALENDAR_POPPER_CLASS } from '../../../constants';
import { clearQuery } from '../../../helpers';
import { media } from '../../../helpers/theme';
import { useMedia } from '../../../hooks/useMedia';
import Input from './index';
import 'react-datepicker/dist/react-datepicker.css';
import { DateInputStyles, ChevronIcon } from './styles';

interface Props {
  value: string;
  onChange: (value: string) => void;
  error: string | null;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
}

const DateInput = ({
  value,
  onChange,
  error,
  label,
  placeholder,
  disabled,
}: Props) => {
  const isAboveTablet = useMedia(clearQuery(media.down('desktop')));
  const [open, setOpen] = useState(false);
  const selectedDate = value ? new Date(value) : null;

  const handleChange = (date: Date | null) => {
    if (date) {
      onChange(date.toString());
    }
  };

  const portalProps = isAboveTablet ? portalMobileProps : portalDesktopProps;

  return (
    <>
      <DateInputStyles />
      <DatePicker
        placeholderText={placeholder}
        onChange={handleChange}
        selected={selectedDate}
        onCalendarOpen={() => setOpen(true)}
        onCalendarClose={() => setOpen(false)}
        dateFormat={'dd/MM/yyyy'}
        showYearDropdown
        showPopperArrow={false}
        yearDropdownItemNumber={15}
        maxDate={new Date()}
        scrollableYearDropdown
        popperClassName={CALENDAR_POPPER_CLASS}
        calendarClassName={CALENDAR_CLASS}
        {...portalProps}
        customInput={
          <Input
            disabled={disabled}
            error={error}
            label={label}
            endAdornment={<ChevronIcon isRotated={open} />}
          />
        }
      />
    </>
  );
};

const portalDesktopProps = { portalId: 'root-portal' };
const portalMobileProps = { withPortal: true };

export default DateInput;
