import styled from 'styled-components';
import {
  body12,
  body16,
  button,
  goldGradient,
  header24,
  media,
  secondaryLightColor,
  secondaryMainColor,
} from '../../helpers/theme';
import BasePaper from '../common/Paper';
import BaseButton from '../common/Button';

export const Wrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  & > *:last-child {
    margin-top: 18px;
  }
`;

export const FormWrapper = styled(BasePaper)`
  align-items: center;
`;

export const Header = styled.h1`
  ${header24};
  background: linear-gradient(90.01deg, ${goldGradient});
  color: ${secondaryMainColor};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`;

export const Subheader = styled.p`
  ${body16};
  color: ${secondaryMainColor};
  white-space: pre-wrap;
  text-align: center;
`;

export const Form = styled.form`
  width: 100%;

  & > * {
    width: 100%;

    :not(:last-child) {
      margin-bottom: 40px;
    }
  }
`;

export const Button = styled(BaseButton)`
  width: 100%;
`;

export const Terms = styled.p`
  ${body12};
  color: ${secondaryLightColor};
  margin: 32px 0 40px;
  text-align: center;
  white-space: pre-wrap;
`;

export const AppLinkWrapper = styled(BasePaper)`
  // ${media.down('tablet')} {
  //   position: absolute;
  //   bottom: 24px;
  // }
`;

export const AppLink = styled.a`
  ${button};
  color: ${secondaryMainColor};
  letter-spacing: -0.08px;
  text-align: center;
  text-decoration: unset;
`;
