import React from 'react';

import Logo from '../common/Logo';
import { Card, Header, Wrapper, SubheaderWhite, Form } from './styles';
import { Button } from '../CallPage/styles';
import InputPassword from '../common/Input/Password';
import Layout from '../Layout';

interface Props {
  onSubmit: (value: string) => void;
  onChange: (value: string) => void;
  loading?: boolean;
  email: string;
  password?: string;
  error: string | null;
}

const SubscribePage = (props: Props) => {
  const { error, onSubmit, email, onChange, password } = props;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e?.target?.value);
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (!password?.trim()?.length) return;
    onSubmit(password);
  };

  return (
    <Layout showLogo={false}>
      <Wrapper>
        <Card>
          <Logo width={160} />
          <Header>Reset your password</Header>
          <div style={{ width: '70%' }}>
            <SubheaderWhite>
              Enter your new password for: {email}
            </SubheaderWhite>
          </div>
          <Form onSubmit={handleSubmit}>
            <InputPassword
              value={password}
              name={'password'}
              disabled={false}
              error={error}
              onChange={handleChange}
              required
            />
            <Button type='submit' inverted color={'primary'}>
              Reset Password
            </Button>
          </Form>
        </Card>
      </Wrapper>
    </Layout>
  );
};

export default SubscribePage;
