import styled from 'styled-components';
import {
  body16,
  goldGradient,
  header24,
  media,
  secondaryMainColor,
} from '../../helpers/theme';
import BaseButton from '../common/Button';
import BasePaper from '../common/Paper';

export const Wrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  & > *:last-child {
    margin-top: 26px;
  }
`;

export const Header = styled.h1`
  ${header24};
  background: linear-gradient(90.01deg, ${goldGradient});
  color: ${secondaryMainColor};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`;

export const Subheader = styled.p`
  ${body16};
  color: ${secondaryMainColor};
  white-space: pre-wrap;
  text-align: center;
`;

export const AppLinkWrapper = styled(BasePaper)`
  ${media.down('tablet')} {
    position: absolute;
    bottom: 24px;
  }
`;

export const Button = styled(BaseButton)`
  width: 100%;
`;
