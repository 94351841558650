import styled from 'styled-components';
import BaseButton from '../common/Button';

export const ActionsWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 18px 0 32px;
  width: 100%;

  & > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const Button = styled(BaseButton)`
  width: 100%;
  max-width: 325px;
  height: 52px;
`;
