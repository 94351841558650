import { getFirebaseApp } from '../api/firebase';
import { useCallback, useRef } from 'react';

export const useManageUsers = () => {
  const app = getFirebaseApp();

  const auth = useRef(app.auth());

  const confirmPasswordReset = useCallback(
    (token: string, password: string) =>
      auth.current.confirmPasswordReset(token, password),
    [],
  );

  const getEmailByToken = useCallback(
    (token: string) => auth.current.verifyPasswordResetCode(token),
    [],
  );

  const verifyEmail = useCallback(
    (token: string) => auth.current.applyActionCode(token),
    [],
  );

  return {
    verifyEmail,
    confirmPasswordReset,
    getEmailByToken,
  };
};
