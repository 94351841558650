import styled from 'styled-components';
import {
  backgroundColor,
  borderRadius,
  getShadow,
  media,
  shadowColor,
} from '../../../helpers/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  box-sizing: border-box;

  ${media.up('tablet')} {
    background: ${backgroundColor};
    border-radius: ${borderRadius};
    box-shadow: ${getShadow(0)} ${shadowColor};
    padding: 32px;
    width: 400px;
  }
`;
