import styled from 'styled-components';
import {
  body20,
  borderGradient,
  getShadow,
  header24,
  lightGoldColor,
  media,
  primaryMainColor,
} from '../../helpers/theme';
import IconButton from '../common/IconButton';
import BaseButton from '../common/Button';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;

  ${media.up('tablet')} {
    height: calc(100vh - 20px);
    max-height: 815px;
    max-width: 375px;
    width: 100%;
    align-self: center;
    background-clip: padding-box;
    border: solid 4px transparent;
    border-radius: 4px;
    z-index: 2;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      border-radius: 8px;
      background: ${borderGradient};
    }
  }
`;

export const MainParticipantInner = styled.div`
  height: 100vh;
  width: 100vw;
`;

export const MockParticipantImage = styled.img`
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  width: 100%;
`;

export const MainParticipantWrapper = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  margin: 4px;
`;

export const MainParticipantVideoWrapper = styled.div`
  position: absolute;
  top: 0px;
  border-radius: 0px;
  left: 0px;
  bottom: 0px;
  overflow: hidden;
  height: 100%;
  width: 100%;

  ${media.up('tablet')} {
    top: 4px;
    border-radius: 4px;
    left: 4px;
    bottom: 4px;
    right: 4px;
    height: calc(100% - 8px);
    width: calc(100% - 8px);
  }
`;

export const Border = styled.div`
  height: 100%;
  border-radius: 16px;
  display: flex;
  overflow: hidden;
  position: relative;
`;

export const LocalParticipantVideoWrapper = styled.div`
  position: absolute;
  border-radius: 15px;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

export const LocalParticipantWrapper = styled.div`
  height: 180px;
  position: absolute;
  top: 24px;
  right: 24px;
  width: 114px;
  border-radius: 16px;
  z-index: 2;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -2px;
    border-radius: inherit;
    background: ${borderGradient};
  }
`;

export const HorizontalWrapper = styled.div`
  bottom: 48px;
  display: flex;
  justify-content: center;
  padding: 0 24px;
  position: absolute;
  width: 100%;

  && > *:not(:first-child) {
    margin-left: 24px;
  }
`;

export const VerticalWrapper = styled.div`
  bottom: 48px;
  position: absolute;
  right: 24px;
  display: flex;
  flex-direction: column;

  && > *:not(:first-child) {
    margin-top: 16px;
  }
`;

export const SmallButton = styled(IconButton)`
  height: 40px;
  width: 40px;
`;

export const ConfirmWrapper = styled.div`
  background: ${lightGoldColor};
  border-radius: 32px;
  box-shadow: ${getShadow(3)};
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
`;

export const ConfirmTitle = styled.h1`
  ${header24};
  color: ${primaryMainColor};
  white-space: pre-wrap;
  text-align: center;
  margin: 0 0 32px;
`;

export const Button = styled(BaseButton)`
  width: 100%;
  padding: 14px 0;

  &&:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const ReconnectingMessage = styled.p`
  ${body20};
  text-align: center;
  white-space: pre-wrap;
  margin: 0 0 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
`;
