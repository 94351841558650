import React, { useCallback, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import SubscribePage from '../components/SubscribePage';
import {
  EMAIL_REGEXP,
  ERRORS,
  SubscribeActionTypes,
  SubscribeInputs,
} from '../constants';
import { SUCCESS } from '../constants/routes';
import { isOlderThan13 } from '../helpers';
import {
  SubscribeInputName,
  SubscribePageActions,
  SubscribePageState,
} from '../types';
import { useSubscribe } from '../hooks/useSubscribe';

const initialState: SubscribePageState = {
  name: '',
  birthDate: '',
  email: '',
  error: null,
};

const reducer = (state: SubscribePageState, action: SubscribePageActions) => {
  switch (action.type) {
    case SubscribeActionTypes.email: {
      return {
        ...state,
        email: action.payload,
      };
    }
    case SubscribeActionTypes.name: {
      return {
        ...state,
        name: action.payload,
      };
    }
    case SubscribeActionTypes.birthDate: {
      return {
        ...state,
        birthDate: action.payload,
      };
    }
    case 'setError': {
      return {
        ...state,
        error: action.payload,
      };
    }
    case 'eraseError': {
      return {
        ...state,
        error: null,
      };
    }
    default:
      return state;
  }
};

const SubscribePageContainer = () => {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [subscribe, loading] = useSubscribe();

  const handleSubmit = useCallback(() => {
    const { email, name, birthDate } = state;
    if (!email || !name || !birthDate) {
      return;
    }

    if (!EMAIL_REGEXP.test(email)) {
      return dispatch({
        type: 'setError',
        payload: {
          name: SubscribeInputs.email,
          message: ERRORS.email,
        },
      });
    }

    if (!isOlderThan13(new Date(birthDate))) {
      return dispatch({
        type: 'setError',
        payload: {
          name: SubscribeInputs.birthDate,
          message: ERRORS.birthDate,
        },
      });
    }

    subscribe({ name, email, birthDate }).then(
      () => {
        history.push(SUCCESS);
      },
      (error: unknown) => {
        if (typeof error !== 'string') return;
        dispatch({
          type: 'setError',
          payload: {
            name: SubscribeInputs.name,
            message: error,
          },
        });
      },
    );
  }, [state, history, subscribe]);

  const handleChange = useCallback(
    (name: SubscribeInputName, value: string) => {
      if (state.error) {
        dispatch({ type: 'eraseError' });
      }

      dispatch({ type: SubscribeActionTypes[name], payload: value });
    },
    [state.error],
  );

  return (
    <SubscribePage
      loading={loading}
      error={state.error}
      email={state.email}
      name={state.name}
      birthDate={state.birthDate}
      onSubmit={handleSubmit}
      onChange={handleChange}
    />
  );
};

export default SubscribePageContainer;
