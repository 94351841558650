/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import { Background } from './components/Layout';
import { BrowserRouter } from 'react-router-dom';
import Router from './router';
import Theme from './theme';
import { VideoProvider } from './components/Video/VideoProvider';
import { useCreateTestRoom } from './hooks/useFetchToken';
import {
  useRegisterTestUser,
  useTestSignIn,
  useSentTestVerificationEmail,
  useTestEmailVerified,
  useSendTestResetPassword,
} from './hooks/firebaseTestHooks';
import AppStateProvider from './containers/AppStateProvider';

const App = () => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('NODE_ENV = ', process.env.NODE_ENV);
    useCreateTestRoom();
    useTestEmailVerified();
    useRegisterTestUser();
    useTestSignIn();
    useSentTestVerificationEmail();
    useSendTestResetPassword();
  }

  return (
    <BrowserRouter>
      <AppStateProvider>
        <VideoProvider>
          <Theme>
            <Background />
            <Router />
          </Theme>
        </VideoProvider>
      </AppStateProvider>
    </BrowserRouter>
  );
};

export default App;
