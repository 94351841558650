import React from 'react';
import Participant from '../Video/Participant';
import { useVideoContext } from '../Video/VideoProvider';
import {
  LocalParticipantWrapper,
  MockParticipantImage,
  LocalParticipantVideoWrapper,
} from './styles';
import LocalParticipantPicture from '../../assets/images/mock-local-participant.png';

export default function ParticipantList() {
  const {
    room: { localParticipant },
  } = useVideoContext();

  return (
    <LocalParticipantWrapper>
      <LocalParticipantVideoWrapper>
        <MockParticipantImage src={LocalParticipantPicture} />;
        <Participant participant={localParticipant} isLocalParticipant={true} />
      </LocalParticipantVideoWrapper>
    </LocalParticipantWrapper>
  );
}
