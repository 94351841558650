import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as routes from '../constants/routes';
import EmailVerification from '../components/EmailVerificationPages/EmailVerifiedPage';
import EmailVerificationExpired from '../components/EmailVerificationPages/LinkExpiredPage';
import ResetPassword from '../components/ResetPasswordPages/NewPasswordPage';
import ResetPasswordExpired from '../components/ResetPasswordPages/LinkExpiredPage';
import PasswordChanged from '../components/ResetPasswordPages/PasswordChangedPage';
import { useManageUsers } from '../hooks/manageUsers';

import {
  MANAGE_USER_TOKEN_PARAM,
  MANAGE_USER_MODE_PARAM,
  EMAIL_VERIFICATION_MODE,
  RESET_PASSWORD_MODE,
} from '../constants';

interface StateRef {
  token: string | null;
  mode: string | null;
  isMounted: boolean;
}

enum Page {
  resetPassword = 'resetPassword',
  passwordChanged = 'passwordChanged',
  resetPasswordExpired = 'resetPasswordExpired',
  emailVerification = 'emailVerification',
  emailVerificationExpired = 'emailVerificationExpired',
}

const isManageUsersMode = (mode: string | null) => {
  return mode === EMAIL_VERIFICATION_MODE || mode === RESET_PASSWORD_MODE;
};

const ManageUsers = () => {
  const { replace, push, location } = useHistory();

  const stateRef = useRef<StateRef>({
    mode: null,
    token: null,
    isMounted: false,
  });

  const [page, setPage] = useState<Page | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [passwordError, setPasswordError] = useState<string | null>(null);
  const [password, setPassword] = useState<string | undefined>();

  const {
    confirmPasswordReset,
    getEmailByToken,
    verifyEmail,
  } = useManageUsers();

  const handleChange = (value: string) => {
    if (passwordError) setPasswordError(null);
    setPassword(value);
  };

  const handleSubmit = (password: string) => {
    const { token } = stateRef.current;
    if (!token) return setPage(Page.resetPasswordExpired);

    confirmPasswordReset(token, password)
      .then(() => setPage(Page.passwordChanged))
      .catch((e) => {
        if (typeof e?.message === 'string') {
          setPasswordError(e.message);
        }
      });
  };

  useEffect(() => {
    if (stateRef.current.isMounted) return;

    const searchParams = new URLSearchParams(location.search);

    stateRef.current = {
      mode: searchParams.get(MANAGE_USER_MODE_PARAM),
      token: searchParams.get(MANAGE_USER_TOKEN_PARAM),
      isMounted: true,
    };

    const { token, mode } = stateRef.current;

    if (!isManageUsersMode(mode) || !token) {
      return push(routes.ROOT);
    }

    replace(routes.MANAGE_USERS);

    if (mode === EMAIL_VERIFICATION_MODE) {
      if (!token) return setPage(Page.emailVerificationExpired);

      verifyEmail(token)
        .then(() => setPage(Page.emailVerification))
        .catch(() => setPage(Page.emailVerificationExpired));
    }

    if (mode === RESET_PASSWORD_MODE) {
      if (!token) return setPage(Page.resetPasswordExpired);

      getEmailByToken(token)
        .then((email) => {
          if (!email) return setPage(Page.resetPasswordExpired);
          setEmail(email);
          setPage(Page.resetPassword);
        })
        .catch(() => setPage(Page.resetPasswordExpired));
    }
  }, [
    location.search,
    push,
    replace,
    confirmPasswordReset,
    getEmailByToken,
    verifyEmail,
  ]);

  switch (page) {
    case Page.emailVerification:
      return <EmailVerification />;
    case Page.emailVerificationExpired:
      return <EmailVerificationExpired />;
    case Page.passwordChanged:
      return <PasswordChanged />;
    case Page.resetPassword:
      if (!email) return null;
      return (
        <ResetPassword
          email={email}
          onChange={handleChange}
          onSubmit={handleSubmit}
          error={passwordError}
          password={password}
        />
      );
    case Page.resetPasswordExpired:
      return <ResetPasswordExpired />;
    default:
      return null;
  }
};

export default ManageUsers;
