import styled from 'styled-components';
import { compose } from '../../../helpers/fp';
import {
  caption2,
  darkenBy20,
  redColor,
  getShadow,
  lightGoldColor,
  whiteColor,
  successColor,
  transparentByOneTenth,
  transparentByThreeTenth,
} from '../../../helpers/theme';
import { Icon, IconProp } from '../../../types';
import { ThemeHelper } from '../../../types/theme';

const transparentOneLightGold = compose(transparentByOneTenth, lightGoldColor);
const transparentThreeLightGold = compose(
  transparentByThreeTenth,
  lightGoldColor,
);
const transparentThreePrimary = compose(transparentByThreeTenth, whiteColor);

const bgColorMapping: Record<Icon, ThemeHelper> = {
  camera: transparentThreeLightGold,
  close: redColor,
  micBlack: whiteColor,
  micWhite: transparentThreeLightGold,
  micMutedWhite: transparentThreeLightGold,
  cameraMutedWhite: transparentThreeLightGold,
  record: transparentThreeLightGold,
  speaker: whiteColor,
  video: successColor,
};

const hoverBgColorMapping: Record<Icon, ThemeHelper> = {
  camera: transparentOneLightGold,
  close: compose(darkenBy20, redColor),
  micBlack: transparentThreePrimary,
  micWhite: transparentOneLightGold,
  record: transparentOneLightGold,
  speaker: transparentThreePrimary,
  video: compose(darkenBy20, successColor),
};

const ICONS_WITH_SHADOW: Icon[] = ['camera', 'micWhite'];

const addShadow = ({ icon }: IconProp): ThemeHelper | null => {
  if (ICONS_WITH_SHADOW.includes(icon)) {
    return getShadow(1);
  }
  return null;
};

export const StyledButton = styled.button<IconProp>`
  align-items: center;
  background-color: ${({ icon }) => bgColorMapping[icon]};
  border-radius: 50%;
  border: unset;
  box-shadow: ${addShadow};
  cursor: pointer;
  display: flex;
  height: 60px;
  justify-content: center;
  transition: background-color 150ms linear;
  width: 60px;

  &:hover {
    background-color: ${({ icon }) => hoverBgColorMapping[icon]};
  }
`;

export const Wrapper = styled.div``;

export const Caption = styled.p`
  ${caption2};
  text-transform: capitalize;
  text-align: center;
  margin: 8px 0 0;
`;
