import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { APP_URI, RATE_VALUES } from '../../constants';
import { ROOT, SUBSCRIBE } from '../../constants/routes';
import { Radio } from '../common/Input';
import Paper from '../common/Paper';
import {
  Button,
  AppLinkWrapper,
  Wrapper,
  Header,
  ContinueLink,
  RateInputs,
} from './styles';
import { useAppStateContext } from '../../containers/AppStateProvider';
import { useRate } from '../../hooks/useRate';
import { Rating } from '../../types';
import Layout from '../Layout';

const RADIO_GROUP_NAME = 'rate';

const RatePage = () => {
  const history = useHistory();
  const [selectedRating, setSelectedRating] = useState<Rating>(null!);
  const { roomName } = useAppStateContext();
  const [rate] = useRate();

  const setRating = (rating: Rating) => (checked: boolean) => {
    if (checked) setSelectedRating(rating);
  };

  const onSubmit = async () => {
    if (selectedRating === null) return;
    if (!roomName) return;

    try {
      await rate({
        roomName: roomName,
        rating: selectedRating,
      });

      history.push(SUBSCRIBE);
    } catch (e) {
      console.log(e, 'Rating failed');
    }
  };

  if (!roomName) {
    history.push(ROOT);
    return null;
  }

  return (
    <Layout>
      <Wrapper>
        <Paper>
          <Header>Was that as fun as it looked?</Header>
          <RateInputs>
            <Radio
              id={'smile'}
              name={RADIO_GROUP_NAME}
              value={RATE_VALUES.smile}
              onChange={setRating(1)}
            />
            <Radio
              id={'meh'}
              name={RADIO_GROUP_NAME}
              value={RATE_VALUES.meh}
              onChange={setRating(2)}
            />
            <Radio
              id={'frown'}
              name={RADIO_GROUP_NAME}
              value={RATE_VALUES.frown}
              onChange={setRating(3)}
            />
          </RateInputs>
          <ContinueLink onClick={onSubmit}>Continue</ContinueLink>
        </Paper>
        <AppLinkWrapper>
          <Button color={'primary'} href={APP_URI}>
            Get the app
          </Button>
        </AppLinkWrapper>
      </Wrapper>
    </Layout>
  );
};

export default RatePage;
