/* eslint-disable no-bitwise,no-nested-ternary,consistent-return */
import * as types from '../types/theme';
import { curry } from './fp';
import { BREAKPOINTS } from '../constants/theme';

export const backgroundColor: types.ThemeHelper = ({ theme }) =>
  theme.colors.background.main;

export const shadowColor: types.ThemeHelper = ({ theme }) =>
  theme.colors.common.shadow;

export const primaryMainColor: types.ThemeHelper = ({ theme }) =>
  theme.colors.primary.main;

export const primaryLightColor: types.ThemeHelper = ({ theme }) =>
  theme.colors.primary.light;

export const secondaryMainColor: types.ThemeHelper = ({ theme }) =>
  theme.colors.secondary.main;

export const secondaryLightColor: types.ThemeHelper = ({ theme }) =>
  theme.colors.secondary.light;

export const secondaryDarkColor: types.ThemeHelper = ({ theme }) =>
  theme.colors.secondary.dark;

export const errorColor: types.ThemeHelper = ({ theme }) =>
  theme.colors.common.error;

export const redColor: types.ThemeHelper = ({ theme }) =>
  theme.colors.common.red;

export const successColor: types.ThemeHelper = ({ theme }) =>
  theme.colors.common.success;

export const lightGoldColor: types.ThemeHelper = ({ theme }) =>
  theme.colors.common.lightGold;

export const skyBlueColor: types.ThemeHelper = ({ theme }) =>
  theme.colors.common.skyBlue;

export const skyBlueDarkColor: types.ThemeHelper = ({ theme }) =>
  theme.colors.common.skyBlueDark;

export const whiteColor: types.ThemeHelper = ({ theme }) =>
  theme.colors.common.white;

export const blackColor: types.ThemeHelper = ({ theme }) =>
  theme.colors.common.skyBlueDark;

export const goldGradient: types.ThemeHelper = ({ theme }) =>
  theme.colors.common.goldGradient;

export const getShadow: types.ShadowHelper = (shadow) => ({ theme }) =>
  theme.shadows[shadow];

export const borderRadius: types.ThemeHelper = ({ theme }) =>
  theme.borderRadius;

export const backgroundGradient: types.ThemeHelper = ({ theme }) =>
  theme.colors.background.gradient;

export const borderGradient: types.ThemeHelper = ({ theme }) =>
  theme.colors.border.gradient;

export const majestiHeader: types.ThemeHelper = ({ theme }) =>
  theme.typography.majestiHeader;

export const header24: types.ThemeHelper = ({ theme }) =>
  theme.typography.header24;

export const body20: types.ThemeHelper = ({ theme }) => theme.typography.body20;

export const body16: types.ThemeHelper = ({ theme }) => theme.typography.body16;

export const body14: types.ThemeHelper = ({ theme }) => theme.typography.body14;

export const body12: types.ThemeHelper = ({ theme }) => theme.typography.body12;

export const button: types.ThemeHelper = ({ theme }) => theme.typography.button;

export const caption2: types.ThemeHelper = ({ theme }) =>
  theme.typography.caption2;

const lightenHexColor = curry((percent: number, color: string | undefined):
  | string
  | undefined => {
  if (!color) return;

  const num = parseInt(color.replace('#', ''), 16);
  const amt = Math.round(2.55 * percent);
  const R = (num >> 16) + amt;
  const B = ((num >> 8) & 0x00ff) + amt;
  const G = (num & 0x0000ff) + amt;

  return `#${(
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
    (G < 255 ? (G < 1 ? 0 : G) : 255)
  )
    .toString(16)
    .slice(1)}`;
});

const hexToRgb = (hex: string | undefined) =>
  hex
    ?.replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`,
    )
    .substring(1)
    .match(/.{2}/g)
    ?.map((x) => parseInt(x, 16));

const makeTransparent = curry(
  (opacity: number, hexColor: string | undefined): string =>
    `rgba(${hexToRgb(hexColor)}, ${opacity})`,
);

export const transparentByOneTenth = makeTransparent(0.1);

export const transparentByEightTenths = makeTransparent(0.8);

export const transparentByThreeTenth = makeTransparent(0.3);

export const darkenBy20 = lightenHexColor(-20);

const between = (min: types.Breakpoint, max: types.Breakpoint): string =>
  `@media screen and (min-width: ${BREAKPOINTS[min]}px) and (max-width: ${BREAKPOINTS[max]}px)`;

const down = (size: types.Breakpoint): string =>
  `@media screen and (max-width: ${BREAKPOINTS[size] - 0.02}px)`;

const up = (size: types.Breakpoint): string =>
  `@media screen and (min-width: ${BREAKPOINTS[size]}px)`;

const safari = (): string => `@media not all and (min-resolution:.001dpcm)`;

export const media = {
  between,
  down,
  up,
  safari,
};
