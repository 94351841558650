import { useEffect, useState } from 'react';
import { clearQuery } from '../helpers';

export const useMedia = (query: string, defaultMatches = true): boolean => {
  const [matches, setMatches] = useState(defaultMatches);

  const q = clearQuery(query);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(q);
    let active = true;

    const listener = () => {
      if (!active) {
        return;
      }

      if (mediaQueryList.matches) {
        setMatches(true);
      } else {
        setMatches(false);
      }
    };

    mediaQueryList.addListener(listener);
    setMatches(mediaQueryList.matches);

    return () => {
      active = false;
      mediaQueryList.removeListener(listener);
    };
  }, [q]);

  return matches;
};
