import React, { ChangeEventHandler, forwardRef, ReactNode } from 'react';
import {
  Wrapper,
  Input as StyledInput,
  Label,
  ErrorMessage,
  AlertIcon,
  EndAdornment,
} from './styles';

export interface Props {
  label?: string;
  type?: string;
  required?: boolean;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  error: string | null;
  placeholder?: string;
  name?: string;
  endAdornment?: ReactNode;
  disabled?: boolean;
}

type Ref = HTMLInputElement;

const Input = forwardRef<Ref, Props>(
  ({ label, error, endAdornment, ...props }, ref) => {
    return (
      <Wrapper>
        <Label>{label}</Label>
        <StyledInput {...props} error={Boolean(error)} ref={ref} />
        {error ? (
          <ErrorMessage>
            <AlertIcon />
            {error}
          </ErrorMessage>
        ) : null}
        {endAdornment ? <EndAdornment>{endAdornment}</EndAdornment> : null}
      </Wrapper>
    );
  },
);

export { default as Radio } from './Radio';
export default Input;
