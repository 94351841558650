import styled from 'styled-components';
import {
  body16,
  majestiHeader,
  primaryLightColor,
  secondaryMainColor,
} from '../../helpers/theme';
import BasePaper from '../common/Paper';
import BaseButton from '../common/Button';

export const Wrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  & > *:first-child {
    margin-bottom: 18px;
  }
`;

export const Card = styled(BasePaper)`
  align-items: center;
`;

export const Header = styled.h1`
  ${majestiHeader};
  color: ${secondaryMainColor};
  text-align: center;
`;

export const SubheaderWhite = styled.p`
  ${body16};
  color: ${primaryLightColor};
  white-space: pre-wrap;
  text-align: center;
  margin-top: 0;
`;

export const Button = styled(BaseButton)`
  width: 100%;
`;

export const Form = styled.form`
  width: 100%;
  padding: 20px 0;
  & > * {
    width: 100%;
    margin-bottom: 40px;
  }
`;
