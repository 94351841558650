import ParticipantTracks from '../Video/ParticipantTracks';
import React from 'react';
import useMainParticipant from '../../hooks/useMainParticipant';
import {
  MainParticipantWrapper,
  MockParticipantImage,
  MainParticipantVideoWrapper,
  MainParticipantInner,
  ReconnectingMessage,
} from './styles';
import MainParticipantImage from '../../assets/images/santa_books.png';
import useParticipantState from '../../hooks/useParticipantState';

export default function MainParticipant() {
  const mainParticipant = useMainParticipant();
  const state = useParticipantState(mainParticipant);

  const videoPriority = 'high';

  const isConnected = state === 'connected' || state === 'reconnected';

  return (
    <MainParticipantWrapper>
      <MainParticipantInner>
        <MainParticipantVideoWrapper>
          <MockParticipantImage src={MainParticipantImage} />;
          <ParticipantTracks
            participant={mainParticipant}
            videoPriority={videoPriority}
            isLocalParticipant={false}
          />
        </MainParticipantVideoWrapper>
        {mainParticipant && !isConnected && (
          <ReconnectingMessage>
            Santa is currently experiencing unstable network, give the elves a
            moment to patch things up.
          </ReconnectingMessage>
        )}
      </MainParticipantInner>
    </MainParticipantWrapper>
  );
}
