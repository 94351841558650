import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SubscribePage from './containers/SubscribePageContainer';
import * as routes from './constants/routes';
import CallPage from './components/CallPage';
import ReceiveCallPage from './components/ReceiveCallPage';
import GrandAccessPage from './components/GrandAccessPage';
import RatePage from './components/RatePage';
import SuccessPage from './components/SuccessPage';

import ManageUsersPage from './containers/ManageUsersPageContainer';

const Router = () => {
  return (
    <Switch>
      <Route path={routes.ROOT} exact component={ReceiveCallPage} />
      <Route path={routes.GRAND_ACCESS} exact component={GrandAccessPage} />
      <Route path={routes.CALL} exact component={CallPage} />
      <Route path={routes.RATE} exact component={RatePage} />
      <Route path={routes.SUBSCRIBE} exact component={SubscribePage} />
      <Route path={routes.SUCCESS} exact component={SuccessPage} />
      <Route path={routes.MANAGE_USERS} exact component={ManageUsersPage} />
    </Switch>
  );
};

export default Router;
