import React from 'react';
import santa from '../../assets/images/santa_avatar.png';
import { Image, ImageWrap } from './styles';

const SantaImage = () => {
  return (
    <ImageWrap>
      <Image src={santa} alt={'Santa'} />
    </ImageWrap>
  );
};

export default SantaImage;
