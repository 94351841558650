import styled from 'styled-components';
import logo from '../../assets/images/logo.png';

const W = 235;
const logoAspectRatio = 235 / 131;

const Logo = styled.img.attrs({
  src: logo,
  alt: 'Logo',
})<{ width?: number }>`
  height: ${({ width }) => (width || W) / logoAspectRatio}px;
  width: ${({ width }) => width || W}px;
`;

export default Logo;
