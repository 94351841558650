import React, { useState } from 'react';
import { ActionsWrapper, Button } from './styles';

interface Props {
  onAccept: () => void;
}

const Actions = ({ onAccept }: Props) => {
  const [audioAllowed, setAudioAllowed] = useState(false);
  const [videoAllowed, setVideoAllowed] = useState(false);

  const toggleCameraAccess = () => {
    if (videoAllowed) return;
    if (audioAllowed) onAccept();
    setVideoAllowed(true);
  };
  const toggleAudioAccess = () => {
    if (audioAllowed) return;
    if (videoAllowed) onAccept();
    setAudioAllowed(true);
  };

  return (
    <ActionsWrapper>
      <Button
        color={videoAllowed ? 'primary' : 'default'}
        inverted={videoAllowed}
        onClick={toggleCameraAccess}>
        Allow Camera Access
      </Button>
      <Button
        color={audioAllowed ? 'primary' : 'default'}
        inverted={audioAllowed}
        onClick={toggleAudioAccess}>
        Allow Audio Access
      </Button>
    </ActionsWrapper>
  );
};

export default Actions;
