import React from 'react';
import { APP_URI } from '../../constants';
import Paper from '../common/Paper';
import { AppLinkWrapper, Button, Header, Subheader, Wrapper } from './styles';
import Layout from '../Layout';

const SuccessPage = () => {
  return (
    <Layout>
      <Wrapper>
        <Paper>
          <Header>You’re all subscribed.</Header>
          <Subheader>
            {"We'll keep you posted with an updates \n for Santa calls."}
          </Subheader>
        </Paper>
        <AppLinkWrapper>
          <Button color={'primary'} href={APP_URI}>
            Get the app
          </Button>
        </AppLinkWrapper>
      </Wrapper>
    </Layout>
  );
};

export default SuccessPage;
