import React, { FC } from 'react';
import { StyledLink } from './styles';

interface Props {
  href: string;
}

const Link: FC<Props> = ({ children, href, ...props }) => {
  return (
    <StyledLink href={href} {...props}>
      {children}
    </StyledLink>
  );
};

export default Link;
