import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RATE, SUBSCRIBE } from '../../constants/routes';
import Modal from '../common/Modal';
import Actions from './Actions';
import ConfirmDialog from './ConfirmDialog';
import LocalParticipant from './LocalParticipant';
import MainParticipant from './MainParticipant';
import { Wrapper } from './styles';
import { useVideoContext } from '../Video/VideoProvider';
import useRoomState from '../../hooks/useRoomState';
import Card from '../Cards/Card';
import { Button } from '../ReceiveCallPage/styles';
import Layout from '../Layout';

const CallPage = () => {
  const history = useHistory();

  const [modalIsOpen, setModalOpen] = useState(false);
  const { room, disconnect } = useVideoContext();
  const roomState = useRoomState();

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleCallEndClick = () => {
    closeModal();
    disconnect();
    history.push(RATE);
  };

  if (!room?.sid) {
    history.push('/');
    return null;
  }

  if (roomState === 'disconnected') {
    return (
      <Layout>
        <Card
          hasAvatar
          title={'Call Dropped'}
          titleType={'secondary'}
          subtitle={
            'This call was dropped due to unstable connections at the North Pole. We apologize and will quickly fix things up,'
          }
          action={
            <Button color={'default'} onClick={() => history.push(SUBSCRIBE)}>
              Close
            </Button>
          }
        />
      </Layout>
    );
  }

  return (
    <Layout>
      <Wrapper>
        <LocalParticipant />
        <MainParticipant />
        <Actions onCancel={openModal} />
        <Modal open={modalIsOpen}>
          <ConfirmDialog
            onCancel={closeModal}
            onCallEndClick={handleCallEndClick}
          />
        </Modal>
      </Wrapper>
    </Layout>
  );
};

export default CallPage;
