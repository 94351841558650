import React from 'react';
import IconButton from '../common/IconButton';
import { ActionsWrapper } from './styles';

interface Props {
  onCancel: () => void;
  onApply: () => void;
}

const Actions = ({ onApply, onCancel }: Props) => {
  // const videoToggle = useLocalVideoToggle()

  return (
    <ActionsWrapper>
      <IconButton onClick={onCancel}>close</IconButton>
      <IconButton onClick={onApply}>video</IconButton>
    </ActionsWrapper>
  );
};

export default Actions;
