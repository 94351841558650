import styled from 'styled-components';
import { MODAL_TRANSITION } from '../../../constants';

export const Wrapper = styled.div<{ isShown: boolean }>`
  position: absolute;
  z-index: 100;
  left: 24px;
  right: 24px;
  transition: transform ${MODAL_TRANSITION}ms linear;
  
  transform:  translateY(${({ isShown }) =>
    isShown ? '50%' : `${window.outerWidth + 1000}px`});
}
`;
