import React from 'react';
import { Button, ConfirmTitle, ConfirmWrapper } from './styles';

interface Props {
  onCallEndClick: () => void;
  onCancel: () => void;
}

const ConfirmDialog = ({ onCallEndClick, onCancel }: Props) => {
  return (
    <ConfirmWrapper>
      <ConfirmTitle>{'Are you sure you want \n to end the call?'}</ConfirmTitle>
      <Button inverted color={'primary'} onClick={onCallEndClick}>
        End call
      </Button>
      <Button color={'secondary'} onClick={onCancel}>
        Cancel
      </Button>
    </ConfirmWrapper>
  );
};

export default ConfirmDialog;
