import { ENDPOINT_SUBSCRIBE } from '../constants';
import { useCallback, useState } from 'react';

const formatDate = (date: string) => {
  const d = new Date(date);
  const yyyy = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
  const mm = new Intl.DateTimeFormat('en', { month: 'numeric' }).format(d);
  const dd = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);

  return `${yyyy}-${mm}-${dd}`;
};

type SubscribeParams = {
  name: string;
  email: string;
  birthDate: string;
};

type SubscribeResponse = {
  status: 'ok';
};

export const useSubscribe = () => {
  const [isFetching, setIsFetching] = useState(false);

  const subscribe = useCallback(
    async ({ name, email, birthDate }: SubscribeParams) => {
      if (!name || !email || !birthDate)
        return Promise.reject('Subscribe: expected 3 arguments');

      const date = formatDate(birthDate);
      try {
        setIsFetching(true);
        const response = await fetch(ENDPOINT_SUBSCRIBE, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            accept: 'application/json',
          },
          body: JSON.stringify({
            name,
            email,
            birthDate: date,
          }),
        });

        if (!response.ok) {
          return Promise.reject('Something went wrong, please try again later');
        }

        const data: SubscribeResponse = await response.json();

        if (data.status !== 'ok') {
          return Promise.reject('Something went wrong, please try again later');
        }

        console.log('Subscribtion succeeded');
      } catch (e) {
        console.error(e, 'Subscription failed');
      } finally {
        setIsFetching(false);
      }
    },
    [],
  );

  return [subscribe, isFetching] as [typeof subscribe, boolean];
};
