import React from 'react';
import { RATE_ICON } from '../../../constants';
import { RateIcon } from '../../../types';
import { RadioWrapper } from './styles';

interface Props {
  id: string;
  name: string;
  value: RateIcon;
  onChange: (checked: boolean) => void;
}

const Radio = ({ id, name, value, onChange }: Props) => {
  const Icon = RATE_ICON[value];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e?.target?.checked);
  };

  return (
    <RadioWrapper>
      <label htmlFor={id}>
        <input
          onChange={handleChange}
          type={'radio'}
          id={id}
          name={name}
          value={value}
        />
        <div>
          <Icon />
        </div>
      </label>
    </RadioWrapper>
  );
};

export default Radio;
