import styled from 'styled-components';
import { body14, secondaryLightColor } from '../../helpers/theme';
import BaseButton from '../common/Button';

export const Terms = styled.p`
  ${body14};
  color: ${secondaryLightColor};
  margin: 0 0 40px;
  text-align: center;
  white-space: pre-wrap;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
`;

export const Button = styled(BaseButton)`
  width: 100%;
  max-width: 325px;
  height: 52px;
`;
