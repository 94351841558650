import React from 'react';
import { Participant, Track } from 'twilio-video';
import Publication from './Publication';
import usePublications from '../../hooks/usePublications';

interface ParticipantTracksProps {
  participant?: Participant;
  videoOnly?: boolean;
  enableScreenShare?: boolean;
  videoPriority?: Track.Priority | null;
  isLocalParticipant?: boolean;
}

export default function ParticipantTracks({
  participant,
  videoOnly,
  videoPriority,
  isLocalParticipant,
}: ParticipantTracksProps) {
  const publications = usePublications(participant);

  if (!publications.length) return null;
  if (!participant) return null;

  return (
    <>
      {publications.map((publication) => (
        <Publication
          key={publication.kind}
          publication={publication}
          participant={participant}
          isLocalParticipant={isLocalParticipant}
          videoOnly={videoOnly}
          videoPriority={videoPriority}
        />
      ))}
    </>
  );
}
