import styled from 'styled-components';
import { secondaryDarkColor, secondaryMainColor } from '../../../helpers/theme';

export const StyledLink = styled.a`
  color: ${secondaryMainColor};
  text-decoration: underline;
  transition: color 150ms linear;

  &:hover {
    color: ${secondaryDarkColor};
  }
`;
