import styled, { createGlobalStyle } from 'styled-components';
import { CALENDAR_CLASS, CALENDAR_POPPER_CLASS } from '../../../constants';
import { compose } from '../../../helpers/fp';
import {
  body12,
  body14,
  body16,
  body20,
  button,
  errorColor,
  getShadow,
  lightGoldColor,
  primaryLightColor,
  primaryMainColor,
  secondaryMainColor,
  skyBlueDarkColor,
  transparentByThreeTenth,
  whiteColor,
} from '../../../helpers/theme';
import { ReactComponent as BaseAlertIcon } from '../../../assets/icons/alert.svg';
import { ReactComponent as BaseChevronIcon } from '../../../assets/icons/chevron_down.svg';

const inputBorderColor = ({ error }: { error: boolean }) =>
  error ? errorColor : secondaryMainColor;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 10;
`;

export const Label = styled.label`
  ${body12};
  color: ${primaryLightColor};
`;

export const Input = styled.input<{ error: boolean }>`
  ${body20};
  background: transparent;
  border: none;
  border-bottom: 1px solid ${inputBorderColor};
  color: ${primaryLightColor};

  //noinspection CssInvalidPseudoSelector
  &::placeholder,
  &::-webkit-datetime-edit {
    color: ${skyBlueDarkColor};
  }

  &:disabled {
    color: ${skyBlueDarkColor};
  }
`;

export const ErrorMessage = styled.div`
  ${body12};
  bottom: -20px;
  color: ${errorColor};
  display: flex;
  left: 0;
  position: absolute;
`;

export const EndAdornment = styled.div`
  bottom: 6px;
  display: flex;
  position: absolute;
  right: 0;
  //z-index: -1;
`;

export const AlertIcon = styled(BaseAlertIcon)`
  margin-right: 8px;
`;

export const RadioWrapper = styled.div`
  input {
    height: 0;
    opacity: 0;
    width: 0;

    &:active ~ div,
    &:checked ~ div {
      background: ${secondaryMainColor};
      box-shadow: ${getShadow(2)};
    }
  }

  div {
    align-items: center;
    background: ${compose(transparentByThreeTenth, lightGoldColor)};
    border-radius: 50%;
    display: flex;
    height: 60px;
    justify-content: center;
    width: 60px;
    transition-duration: 150ms;
    transition-timing-function: linear;
    transition-property: background, box-shadow;
    box-shadow: ${getShadow(1)};

    &:hover {
      cursor: pointer;
      background: ${secondaryMainColor};
    }
  }
`;

export const DateInputStyles = createGlobalStyle`
  .react-datepicker.${CALENDAR_CLASS} {
    ${body14};
    background-color: ${lightGoldColor};
    border-radius: 32px;
    color: ${primaryMainColor};
    box-shadow: ${getShadow(3)};
    
    .react-datepicker__header {
      background-color: ${lightGoldColor};
      border-top-left-radius: 32px;
      border-top-right-radius: 32px;
      color: ${primaryMainColor};
    }
    
    .react-datepicker__current-month {
      ${button}
    }
    
    .react-datepicker__day,
    .react-datepicker__day-name,
    .react-datepicker__current-month {
      color: ${primaryMainColor};
    }
    
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected,
    .react-datepicker__year-option--selected_year {
      color: ${whiteColor};
      background-color: ${secondaryMainColor};
    }
    
    .react-datepicker__year-read-view {
      margin-top: 4px;
    }
    
    .react-datepicker__year-read-view--selected-year {
      ${body16};
      margin-right: 8px;
    }
    
    .react-datepicker__navigation--previous {
       border-right-color: ${secondaryMainColor};
    }
    
    .react-datepicker__navigation--next {
      border-left-color: ${secondaryMainColor};
    }
    
    .react-datepicker__year-read-view--down-arrow {
      border-top-color: ${secondaryMainColor};
    }
    
    .react-datepicker__year-dropdown {
      background-color: ${lightGoldColor};
    }
  }
  
  .react-datepicker-popper.${CALENDAR_POPPER_CLASS} {
    z-index: 20;
  }
`;

export const ChevronIcon = styled(BaseChevronIcon)<{ isRotated: boolean }>`
  transform: rotate(${({ isRotated }) => (isRotated ? '180deg' : 0)});
  transition: transform 150ms linear;
`;

export const EndAdornmentButton = styled.button.attrs({ type: 'button' })`
  background-color: transparent;
  border: none;
  padding: 0;
  width: 28px;
  height: 24px;
  outline: none;
`;
