import styled, { css } from 'styled-components';
import { compose } from '../../../helpers/fp';
import {
  borderRadius,
  button,
  lightGoldColor,
  whiteColor,
  secondaryMainColor,
  transparentByThreeTenth,
  primaryMainColor,
} from '../../../helpers/theme';
import { Button } from '../../../types';

const primaryButtonStyles = css`
  background: ${primaryMainColor};
  border-radius: ${borderRadius};
  border: 1px solid ${secondaryMainColor};
  box-shadow: -4px -4px 14px rgba(255, 255, 255, 0.05), 4px 4px 14px #000e17;

  padding: 18px 0;
  &:hover {
    background: ${secondaryMainColor};
    border-color: transparent;
  }

  &:disabled {
    background: ${primaryMainColor};
    border-color: ${secondaryMainColor};
  }
`;

const invertedPrimaryButtonStyles = css`
  background: ${secondaryMainColor};
  border-radius: ${borderRadius};
  border: 1px solid ${secondaryMainColor};
  box-shadow: -4px -4px 14px rgba(255, 255, 255, 0.05), 4px 4px 14px #000e17;
  padding: 18px 0;

  &:hover {
    background: ${primaryMainColor};
  }
`;

const secondaryButtonStyles = css`
  background: ${lightGoldColor};
  border-radius: ${borderRadius};
  border: 1px solid ${secondaryMainColor};
  box-shadow: -4px -4px 12px rgba(255, 255, 255, 0.8), 4px 4px 12px #dbcebe;

  color: ${primaryMainColor};
  padding: 18px 0;
  &:hover {
    background: ${primaryMainColor};
    color: ${whiteColor};
  }
`;

const defaultButtonStyles = css`
  background: ${compose(transparentByThreeTenth, lightGoldColor)};
  border: none;
  border-radius: 100px;
  box-shadow: 4px 4px 14px rgba(0, 14, 23, 0.2),
    -4px -4px 14px rgba(255, 255, 255, 0.05);

  &:hover {
    background: ${secondaryMainColor};
  }
`;

const buttonStyleMapping: Record<Button, typeof defaultButtonStyles> = {
  default: defaultButtonStyles,
  primary: primaryButtonStyles,
  secondary: secondaryButtonStyles,
};

const invertedButtonStyleMapping: Record<Button, typeof defaultButtonStyles> = {
  default: defaultButtonStyles,
  primary: invertedPrimaryButtonStyles,
  secondary: secondaryButtonStyles,
};

const buttonStyles = css<{ color: Button; inverted?: boolean }>`
  ${button};
  cursor: pointer;
  color: ${whiteColor};
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  transition-duration: 150ms;
  transition-timing-function: linear;
  transition-property: background, box-shadow;
  outline: none;
  ${({ color, inverted }) =>
    inverted ? invertedButtonStyleMapping[color] : buttonStyleMapping[color]};

  &:disabled {
    cursor: not-allowed;
  }
`;

export const StyledButton = styled.button<{
  color: Button;
  inverted?: boolean;
}>`
  ${buttonStyles};
`;

export const LinkButton = styled.a`
  ${buttonStyles};
`;
