import React, {
  ChangeEventHandler,
  FormEventHandler,
  useCallback,
} from 'react';
import {
  TERMS_URI,
  SubscribeInputs,
  PRIVACY_POLICY_URI,
  APP_URI,
} from '../../constants';
import { clearQuery } from '../../helpers';
import { media } from '../../helpers/theme';
import { useMedia } from '../../hooks/useMedia';
import { SubscribeInputName, SubscribePageState } from '../../types';
import Input from '../common/Input';
import Link from '../common/Link';
import DateInput from '../common/Input/Date';
import {
  Form,
  FormWrapper,
  Header,
  Wrapper,
  Button,
  Terms,
  AppLink,
  AppLinkWrapper,
  Subheader,
} from './styles';
import Layout from '../Layout';

interface Props extends SubscribePageState {
  loading: boolean;
  onChange: (name: SubscribeInputName, value: string) => void;
  onSubmit: () => void;
}

const FORM_ID = 'signup';

const SubscribePage = ({
  email,
  birthDate,
  name,
  error,
  loading,
  onSubmit,
  onChange,
}: Props) => {
  const isMobileView = useMedia(clearQuery(media.down('tablet')));

  const handleChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target }) => onChange(target.name as SubscribeInputName, target.value),
    [onChange],
  );

  const handleDateChange = useCallback(
    (value: string) => {
      onChange(SubscribeInputs.birthDate, value);
    },
    [onChange],
  );

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      onSubmit();
    },
    [onSubmit],
  );

  const terms = (
    <Terms>
      {'By subscribing, you are agreeing to our '}
      <Link href={TERMS_URI}>terms of service</Link>
      {' and '}
      <Link href={PRIVACY_POLICY_URI}>privacy policy</Link>.
    </Terms>
  );

  return (
    <Layout>
      <Wrapper>
        <FormWrapper>
          <Header>Subscribe to the North Pole!</Header>
          <Subheader>
            {'For Santa specials, feel free to drop your \n information below.'}
          </Subheader>
          <Form id={FORM_ID} onSubmit={handleSubmit}>
            <Input
              label={'Type Name'}
              value={name}
              name={SubscribeInputs.name}
              type={'text'}
              disabled={loading}
              error={
                error?.name === SubscribeInputs.name ? error.message : null
              }
              onChange={handleChange}
              required
            />
            <DateInput
              label={'Date of Birth'}
              value={birthDate}
              placeholder={'DD/MM/YYYY'}
              error={
                error?.name === SubscribeInputs.birthDate ? error.message : null
              }
              onChange={handleDateChange}
            />
            <Input
              label={'Email'}
              value={email}
              name={SubscribeInputs.email}
              type={'email'}
              disabled={loading}
              error={
                error?.name === SubscribeInputs.email ? error.message : null
              }
              onChange={handleChange}
              required
            />
            {terms}
            <Button
              color={'primary'}
              type={'submit'}
              form={FORM_ID}
              disabled={
                loading || !email || !birthDate || !name || Boolean(error)
              }>
              Subscribe
            </Button>
          </Form>
        </FormWrapper>
        {isMobileView ? (
          <AppLink href={APP_URI}>Get the app</AppLink>
        ) : (
          <AppLinkWrapper>
            <Button color={'primary'} href={APP_URI}>
              Get the app
            </Button>
          </AppLinkWrapper>
        )}
      </Wrapper>
    </Layout>
  );
};

export default SubscribePage;
