import React from 'react';
import { APP_URI } from '../../constants';
import { media } from '../../helpers/theme';
import { useMedia } from '../../hooks/useMedia';
import {
  Card,
  HeaderPrimary,
  Wrapper,
  Button,
  AppLink,
  AppLinkWrapper,
  Subheader,
} from './styles';
import Layout from '../Layout';

interface Props {
  loading?: boolean;
}

const SubscribePage = (_: Props) => {
  const isMobileView = useMedia(media.down('tablet'));

  return (
    <Layout>
      <Wrapper>
        <Card>
          <HeaderPrimary>Your email has been verified.</HeaderPrimary>
          <Subheader>
            You can get back to the app and be the Santa you’ve always waited to
            be!
          </Subheader>
        </Card>
        {isMobileView ? (
          <AppLink href={APP_URI}>Get the app</AppLink>
        ) : (
          <AppLinkWrapper>
            <Button color={'primary'} href={APP_URI}>
              Get the app
            </Button>
          </AppLinkWrapper>
        )}
      </Wrapper>
    </Layout>
  );
};

export default SubscribePage;
