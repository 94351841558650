import React, { FC } from 'react';
import {
  GlobalStyle,
  LogoWrapper,
  Wrapper,
  Gradient,
  BackgroundImage,
} from './styles';

import LogoImg from '../common/Logo';

interface LayoutProps {
  showLogo?: boolean;
}

export const Background = () => {
  return (
    <>
      <Gradient />
      <BackgroundImage />
      <GlobalStyle />
    </>
  );
};

const Layout: FC<LayoutProps> = ({ showLogo = true, children }) => {
  return (
    <>
      {showLogo && (
        <LogoWrapper>
          <LogoImg />
        </LogoWrapper>
      )}
      <Wrapper>{children}</Wrapper>;
    </>
  );
};

export default Layout;
