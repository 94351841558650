import { YEAR_IN_MILLISECONDS } from '../constants';
import { compose, curry, replace } from './fp';

export const clearQuery = replace('@media', '');

export const graterThan = curry(
  (toCompare: number, value: number) => value > toCompare,
);

const dateDiffToNow = (date: Date) => new Date().valueOf() - date.valueOf();
const dateDiffToZeroYear = (date: number) => date - new Date(0).valueOf();
const countYears = (date: number) => date / YEAR_IN_MILLISECONDS;
const graterThan13 = graterThan(13);

export const isOlderThan13 = compose(
  graterThan13,
  countYears,
  dateDiffToZeroYear,
  dateDiffToNow,
);
