import React, { ReactNode } from 'react';
import { PRIVACY_POLICY_URI } from '../../constants';
import SantaImage from './SantaImage';
import { Title, Wrapper, Subtitle, Link, Content, Header } from './styles';

interface Props {
  action?: ReactNode;
  desc?: ReactNode;
  subtitle?: string;
  hasAvatar?: boolean;
  title?: string;
  hasDownloadAppLink?: boolean;
  titleType?: 'primary' | 'secondary' | 'default';
}

const titleTypeMapping = {
  primary: Header,
  secondary: Title,
  default: Title,
};

const Card = ({
  action,
  desc,
  subtitle,
  hasAvatar,
  title,
  titleType = 'default',
  hasDownloadAppLink,
}: Props) => {
  const T = titleTypeMapping[titleType];

  return (
    <Wrapper>
      <Content>
        {/* Santa image */}
        {hasAvatar && <SantaImage />}

        {/* Title */}
        {title && <T>{title}</T>}
        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
        {desc || null}
        {action || null}

        {/* Download app for full experience*/}
        {hasDownloadAppLink && (
          <Link href={PRIVACY_POLICY_URI}>
            Download the app for full experience
          </Link>
        )}
      </Content>
    </Wrapper>
  );
};

export default Card;
