import styled from 'styled-components';
import {
  button,
  goldGradient,
  header24,
  media,
  secondaryMainColor,
  whiteColor,
} from '../../helpers/theme';
import BaseButton from '../common/Button';
import BasePaper from '../common/Paper';

export const Wrapper = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  & > *:last-child {
    margin-top: 26px;
  }
`;

export const Header = styled.h1`
  ${header24};
  background: linear-gradient(90.01deg, ${goldGradient});
  color: ${secondaryMainColor};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
`;

export const ContinueLink = styled.button`
  ${button};
  color: ${whiteColor};
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 10px;
  cursor: pointer;
`;

export const AppLinkWrapper = styled(BasePaper)`
  ${media.down('tablet')} {
    position: absolute;
    bottom: 24px;
  }
`;

export const Button = styled(BaseButton)`
  width: 100%;
`;

export const RateInputs = styled.div`
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
  width: 244px;
`;
