import firebase from 'firebase';
import { FB_API_KEY, FB_PROJECT_ID } from '../constants';

let App: firebase.app.App | undefined;

export const getFirebaseApp = () => {
  if (App) return App;

  App = firebase.initializeApp({
    apiKey: FB_API_KEY,
    projectId: FB_PROJECT_ID,
  });

  return App;
};
