import React from 'react';

import Logo from '../common/Logo';
import { Card, Header, Wrapper, SubheaderWhite } from './styles';
import Layout from '../Layout';

const LinkExpiredPage = () => {
  return (
    <Layout showLogo={false}>
      <Wrapper>
        <Card>
          <Logo width={160} />
          <Header>Link Expired</Header>
          <SubheaderWhite>
            Your request to reset your password has expired or the link has
            already been used
          </SubheaderWhite>
        </Card>
      </Wrapper>
    </Layout>
  );
};

export default LinkExpiredPage;
