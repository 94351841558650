import { DefaultTheme } from 'styled-components';

const colors = {
  midnightBlue: '#041C2C',
  skyBlue: '#BECDCC',
  skyBlueDark: '#717878',
  enchantGold: '#AE9277',
  lightGold: '#F3EFEA',
  darkGold: '#937E6B',
  midGold: '#E0DACD',
  roseRed: '#CD796E',
  lightRed: '#E6AFA7',
  black: '#1B1B1B',
  white: '#fff',
  red: '#E94832',
  green: '#01C343',
  midnight: '#00020B',
  midnightBlueGradient:
    'linear-gradient(325.97deg, #041C2C 22.69%, #345165 198.31%)',
  verticalGradient:
    '#A58569 0.01%, #C2A06A 25%, #DFC999 51%, #C2A06A 76%, #A58569 100%',
};

export const FONTS = {
  majestiBanner: 'Majesti Banner Bold',
  proximaNova: 'Proxima Nova',
};

const typography = {
  majestiHeader: `
    font-family: ${FONTS.majestiBanner};
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.24px;
    line-height: 20px;
  `,
  header24: `
    font-family: ${FONTS.proximaNova};
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: -0.32px;
    line-height: 30px;
  `,
  body20: `
    font-family: ${FONTS.proximaNova};
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 0.38px;
    line-height: 28px;
  `,
  body16: `
    font-family: Proxima Nova;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    letter-spacing: -0.8px;
  `,
  body14: `
    font-family: ${FONTS.proximaNova};
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: -0.08px;
    line-height: 22px;
  `,
  body12: `
    font-family: Proxima Nova;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 16px;
    letter-spacing: 0.07px;
  `,
  button: `
    font-family: ${FONTS.proximaNova};
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
    letter-spacing: -0.08px;
  `,
  caption2: `
    font-family: ${FONTS.proximaNova};
    font-size: 11px;
    font-weight: 300;
    letter-spacing: 0.07px;
    line-height: 13px;
  `,
};

export enum BREAKPOINTS {
  phone = 375,
  tablet = 768,
  desktop = 1025,
}

const theme: DefaultTheme = {
  colors: {
    primary: {
      light: colors.skyBlue,
      main: colors.midnightBlue,
    },
    secondary: {
      light: colors.midGold,
      main: colors.enchantGold,
      dark: colors.darkGold,
    },
    background: {
      main: colors.midnightBlue,
      gradient: colors.midnightBlueGradient,
    },
    border: {
      gradient: `linear-gradient(${colors.verticalGradient})`,
    },
    common: {
      white: colors.white,
      black: colors.black,
      lightGold: colors.lightGold,
      skyBlueDark: colors.skyBlueDark,
      red: colors.red,
      error: colors.roseRed,
      success: colors.green,
      shadow: colors.midnight,
      goldGradient: colors.verticalGradient,
    },
  },
  borderRadius: '24px',
  typography,
  shadows: {
    0: '0 8px 40px',
    1: '4px 4px 14px rgba(0, 14, 23, 0.2), -4px -4px 14px rgba(255, 255, 255, 0.05)',
    2: '-4px -4px 14px rgba(255, 255, 255, 0.05), 4px 4px 14px #000E17',
    3: '0 0 80px #001523',
    4: '-4px -4px 12px rgba(255, 255, 255, 0.8), 4px 4px 12px #DBCEBE;',
  },
};

export default theme;
